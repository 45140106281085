

import { Box, Container, Typography } from '@mui/material';
import React from 'react'
import { fontSizeTheme, fontSizeThemeTitle } from './../../theme/ThemeFontSize';

export default function PrivacyPolitics() {
    return (
        <Box
            minHeight="70vh"
            sx={{ position: 'relative', display: 'flex', flexDirection: 'row', px: 1, py: 4 }}>

            <Container maxWidth="md">
                <Typography fontSize={fontSizeThemeTitle} sx={{ pb: 1 }} variant="h6" color="#042D74" fontWeight={600} align='center' gutterBottom>
                    Politicas de privacidad
                </Typography>
                <Typography display="block" fontSize={fontSizeTheme} variant="body1" color='text.secondary' fontWeight={500} align='justify' gutterBottom style={{ lineHeight: "30px" }}>
                    Estos términos de la política de privacidad, en adelante “Términos”, son aplicables para el uso de los servicios ofrecidos por la Municipalidad del distrito seleccionado, llamado en adelante como “La municipalidad” para la presente aplicación (en adelante denominada la “APP”). El usuario manifiesta y declara expresamente que acepta todos los Términos establecidos en el presente documento referido a la instalación y uso de la APP. La instalación y uso de la APP se entenderá como plena e indubitable manifestación de la voluntad del usuario y de la aceptación  de los presentes Términos.
                </Typography>
                <Typography display="block" fontSize={fontSizeTheme} variant="body1" color='text.secondary' fontWeight={500} align='justify' gutterBottom style={{ lineHeight: "30px" }}>
                    En la Municipalidad nos preocupamos por la protección y privacidad de los datos personales de nuestros usuarios. Por ello, garantizamos la absoluta confidencialidad de los mismos y empleamos altos estándares de seguridad conforme a lo establecido en la Ley de Protección de Datos Personales “Ley N° 29733” y su Reglamento aprobado por el Decreto Supremo N° 003-2013-JUS (“las normas de protección de datos personales”).
                </Typography>
                <Typography display="block" fontSize={fontSizeTheme} variant="body1" color='text.secondary' fontWeight={500} align='justify' gutterBottom style={{ lineHeight: "30px" }}>
                    Toda información entregada a “la Municipalidad” mediante la APP y/o cualquier forma de registro que utilicen los usuarios, será objeto de tratamiento automatizado e incorporado a una o más bases de datos de las que “La Municipalidad” será titular y responsable, conforme  a los términos previsto por la Ley.
                </Typography>
                <Typography display="block" fontSize={fontSizeTheme} variant="body1" color='text.secondary' fontWeight={500} align='justify' gutterBottom style={{ lineHeight: "30px" }}>
                    De acuerdo a las normas de protección de datos personales, “la Municipalidad” también puede usar y compartir los datos personales con autoridades y terceros autorizados por la ley con la finalidad de cumplir con las obligaciones señaladas en las normas peruanas o internacionales, incluyendo las vinculadas al sistema de prevención de lavado de activos y normas prudenciales.
                </Typography>
                <Typography display="block" fontSize={fontSizeTheme} variant="body1" color='text.secondary' fontWeight={500} align='justify' gutterBottom style={{ lineHeight: "30px" }}>
                    Los datos personales incluyen toda la información que haya sido proporcionada y a los que se haya podido acceder de forma física, oral o electrónica a través de fuentes accesibles al público. Si se cuenta con la autorización respectiva, la municipalidad podrá incluir la información de los usuarios en sus bases de datos personales; almacenarla, procesarla y transferirla a alguna entidad pública de ser requerida teniendo consideraciones establecidas en la Ley de Protección de datos Personales “Ley N° 29733” y su Reglamento aprobado por el Decreto Supremo N° 003-2013-JUS (“las normas de protección de datos personales”). Asimismo, se podrá ofrecer cualquiera de los servicios de la Municipalidad a  través de cualquier medio escrito, verbal y/o informático.
                    <br></br>
                </Typography>
            </Container>
        </ Box>
    )
}
