import { makeStyles } from '@mui/styles';
import React from 'react';
import AppBarComponent from './AppBar';
import { Box } from '@mui/material';
import FooterComponent from './footer';


const useStyles = makeStyles((theme) => {
    return {
        toolbar: theme.mixins.toolbar
    }
})

function Layout({ children }) {
    const classes = useStyles();
    return (
        <div>
            <AppBarComponent />
            <Box>
                <div className={classes.toolbar}> </div> 
                {children}
            </Box>
            <FooterComponent />
        </div>
    );
}

export default Layout;