import React from 'react'
import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';


export default function Message(props) {

    const { notify, setNotify } = props;


    const handleClose = (event, reason) => {
        setNotify({
            ...notify,
            isOpen: false
        })
    }


    return (
        <Snackbar
            open={notify.isOpen}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <Alert severity={notify.type} onClose={handleClose}>{notify.message}</Alert>
        </Snackbar>
    )
}
