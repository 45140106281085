import React from "react";
function NotFound(){
    return (
        <div style={{
            display: 'flex',  
            justifyContent:'center', 
            alignItems:'center', 
            height: '100vh',
            backgroundColor : '#2a2f4c' 
        }}>
            <img src="/images/404code.png" width={500} alt="Cat Not Found 404"/>
        </div>
        
    )
}
export default NotFound;