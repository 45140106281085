import { Box, Container, Typography, Grid } from '@mui/material';
import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { makeStyles } from '@mui/styles';
import { fontSizeTheme, fontSizeThemeTitle } from './../../theme/ThemeFontSize';


const useStyles = makeStyles((theme) => {
    return {
        image: {
            height: '70%', width: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        },
        title: {
            fontFamily: 'Pushster',
            fontSize: '4.5em',
            color: '#fafafa',
            fontWeight: '700',
            textAlign: 'center'
        },

    }
})

export default function WebSection() {
    const classes = useStyles();

    return (
        <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'row', px: 1, py: 4 }}>
            <Grid container >
                <Grid
                    sx={{
                        p: {
                            lg: 3,
                            md: 3,
                            sm: 2,
                            xs: 2
                        }
                    }}
                    order={{ xs: 2, sm: 2, md: 1, lg: 1 }}
                    item xs={12} sm={12} md={6}>
                    <Container maxWidth="xl" sx={{ p: 3 }}>
                        <img src="/images/web/web.webp" alt="Sistema Web Keeper" className={classes.image} />
                    </Container>
                </Grid>
                <Grid item
                    order={{ xs: 1, sm: 1, md: 2, lg: 2 }}
                    xs={12} sm={12} md={6}>
                    <Container maxWidth="sm" sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        p: 2.5
                    }}>
                        <Typography fontSize={fontSizeThemeTitle} sx={{ pb: 1 }} variant="h6" color='text.primary' fontWeight={600} align='center' gutterBottom>
                            Keeper en las municipalidades
                        </Typography>
                        <Typography fontSize={fontSizeTheme} variant="body1" color='text.secondary' fontWeight={400} align='left' gutterBottom>
                            Simplifica la gestión de las municipalidades para atender alertas y emergencias en su jurisdicción.
                        </Typography>

                        <Container maxWidth="md" sx={{
                            display: 'flex',
                            flexDirection: 'column',

                            p: 2
                        }}>
                            <Container maxWidth="xl" sx={{
                                display: 'flex',
                                flexDirection: 'row',

                            }}>
                                <CheckCircleIcon fontSize='medium' sx={{ pt: 0.5 }} />
                                <Typography fontSize={fontSizeTheme} sx={{ px: 1 }} variant="body1" color='text.secondary' align='justify' gutterBottom>
                                    Mejora la atención y el tiempo de respuesta ante situaciones de emergencia en la población. </Typography>
                            </Container>
                            <Container maxWidth="xl" sx={{
                                display: 'flex',
                                flexDirection: 'row',

                            }}>
                                <CheckCircleIcon fontSize='medium' sx={{ pt: 0.5 }} />
                                <Typography fontSize={fontSizeTheme} sx={{ px: 1 }} variant="body1" color='text.secondary' align='justify' gutterBottom>
                                    Permite asignar de manera eficiente los recursos de la municipalidad.  </Typography>
                            </Container>
                            <Container maxWidth="xl" sx={{
                                display: 'flex',
                                flexDirection: 'row',

                            }}>
                                <CheckCircleIcon fontSize='medium' sx={{ pt: 0.5 }} />
                                <Typography fontSize={fontSizeTheme} sx={{ px: 1 }} variant="body1" color='text.secondary' align='justify' gutterBottom>
                                    Hace uso eficiente de los recursos públicos.
                                </Typography>
                            </Container>
                            <Container maxWidth="xl" sx={{
                                display: 'flex',
                                flexDirection: 'row',

                            }}>
                                <CheckCircleIcon fontSize='medium' sx={{ pt: 0.5 }} />
                                <Typography fontSize={fontSizeTheme} sx={{ px: 1 }} variant="body1" color='text.secondary' align='justify' gutterBottom>
                                    Apoya en la toma de decisiones brindando reportes de diversa índole.
                                </Typography>
                            </Container>
                            <Container maxWidth="xl" sx={{
                                display: 'flex',
                                flexDirection: 'row',

                            }}>
                                <CheckCircleIcon fontSize='medium' sx={{ pt: 0.5 }} />
                                <Typography fontSize={fontSizeTheme} sx={{ px: 1 }} variant="body1" color='text.secondary' align='justify' gutterBottom>
                                    Portátil, se puede gestionar el trabajo desde cualquier lugar.
                                </Typography>
                            </Container>

                        </Container>
                    </Container>
                </Grid>
            </Grid>
        </Box>
    )
}


/*
Keeper facilita la administración y gestión pública, digitalizando procesos y procedimientos indispensables para que las autoridades puedan brindar servicios básicos de respuesta a alertas y emergencias
Keeper facilita la asignación, planificación, ejecución, cierre y documentación de las tareas necesarias para atender eficientemente las alertas y emergencias:

Mejora los tiempos de respuesta de los diferentes servicios.

Fomenta el trabajo en equipo mediante el uso de metodologías agiles.

Hace uso eficiente de los recursos públicos.

Facilita la toma de decisiones basados en datos actuales e históricos.

Hace posible el trabajo remoto desde cualquier lugar y dispositivo y en cualquier momento.

Provee un canal de comunicación digital nuevo, moderno, intuitivo y eficiente.
*/