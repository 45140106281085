import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { CardActionArea } from '@mui/material';
import { makeStyles } from '@mui/styles';


// - Multi carousel section --
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 1600 },
        items: 6
    },
    desktop: {
        breakpoint: { max: 1600, min: 1200 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1200, min: 670 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 670, min: 0 },
        items: 1
    }
};

const cardContentHeight = 150;


const useStyles = makeStyles((theme) => {
    return {
        card: {
            border: 2,
            
        },
    }
})

function MultiCarousel2(props) {
    const classes = useStyles();

    return (
        <Carousel responsive={responsive}
            swipeable={true}
            draggable={false}
            //showDots={true}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={props.deviceType !== "mobile" ? true : false}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="transform 1000ms ease-in-out"
            transitionDuration={5000}
            containerClass="carousel-container"
            //removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px">

            <Card className={classes.card} sx={{ maxWidth: 300, borderRadius: 1, m: '10px' }}> {/*  boxShadow:'0 1px 8px 3px rgba(217, 167, 138, 1)', mb:2*/}
                <CardActionArea >
                    <Box position='relative'>
                        <CardMedia
                            component="img"
                            height="180"
                            image="/images/card1.webp"
                            alt="Serenazgo"
                        />
                        <Container
                            sx={{
                                position: 'absolute',
                                left: -10,
                                right: 0,
                                bottom: -22,
                            }}
                        >
                            <img src="/images/icon1.webp" alt="Serenazgo" className={classes.image} />
                        </Container>
                    </Box>
                    <Box position='relative'
                        height={cardContentHeight}
                    >
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div" fontWeight={600}>
                                Serenazgo
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Brindamos asistencia ante cualquier situación</Typography>
                        </CardContent>
                    </Box>

                </CardActionArea>
            </Card>

            <Card className={classes.card} sx={{ maxWidth: 300, borderRadius: 1, m: '10px' }}> {/*  boxShadow:'0 1px 8px 3px rgba(217, 167, 138, 1)', mb:2*/}
                <CardActionArea >
                    <Box position='relative' >
                        <CardMedia
                            component="img"
                            height="180"
                            image="/images/card2.webp"
                            alt="Ambulancia"
                        />
                        <Container
                            sx={{
                                position: 'absolute',
                                left: -10,
                                right: 0,
                                bottom: -22,
                                //height: 10,
                            }}
                        >
                            <img src="/images/icon2.png" alt="Ambulancia" className={classes.image} />
                        </Container>
                    </Box>
                    <Box position='relative' height={cardContentHeight}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" fontWeight={600} component="div">
                                Ambulancia
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Para caso de accidentes y atención de heridos.
                            </Typography>
                        </CardContent>
                    </Box>

                </CardActionArea>
            </Card>
            <Card className={classes.card} sx={{ maxWidth: 300, borderRadius: 1, m: '10px' }}> {/*  boxShadow:'0 1px 8px 3px rgba(217, 167, 138, 1)', mb:2*/}
                <CardActionArea >
                    <Box position='relative'>
                        <CardMedia
                            component="img"
                            height="180"
                            image="/images/card3.webp"
                            alt="Bomberos"
                        />
                        <Container
                            sx={{
                                position: 'absolute',
                                left: -10,
                                right: 0,
                                bottom: -22,
                                //height: 10,
                            }}
                        >
                            <img src="/images/icon3.png" alt="Bomberos" className={classes.image} />
                        </Container>
                    </Box>
                    <Box position='relative' height={cardContentHeight}>
                        <CardContent>
                            <Typography gutterBottom variant="h6" fontWeight={
                                600
                            } component="div">
                                Bomberos
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Disponible para rescates ante situación de incendio
                            </Typography>
                        </CardContent>
                    </Box>

                </CardActionArea>
            </Card>
            <Card className={classes.card} sx={{ maxWidth: 300, borderRadius: 1, m: '10px' }}> {/*  boxShadow:'0 1px 8px 3px rgba(217, 167, 138, 1)', mb:2*/}
                <CardActionArea >
                    <Box position='relative'>
                        <CardMedia
                            component="img"
                            height="180"
                            image="/images/card4.webp"
                            alt="Violencia familiar"
                        />
                        <Container
                            sx={{
                                position: 'absolute',
                                left: -10,
                                right: 0,
                                bottom: -22,
                                //height: 10,
                            }}
                        >
                            <img src="/images/icon4.png" alt="Violencia Familiar" className={classes.image} />
                        </Container>
                    </Box>
                    <Box position='relative' height={cardContentHeight}>
                        <CardContent>
                            <Typography gutterBottom variant="h6" fontWeight={
                                600
                            } component="div">
                                Violencia familiar
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {/* Si es parte de un grupo familiar y se encuentra en una situacion de violencia, puede acudir a nosotros directamente. */}
                                Brindamos asistencia ante situaciones de violencia.
                            </Typography>
                        </CardContent>
                    </Box>

                </CardActionArea>
            </Card>
            <Card className={classes.card} sx={{ maxWidth: 300, borderRadius: 1, m: '10px' }}> {/*  boxShadow:'0 1px 8px 3px rgba(217, 167, 138, 1)', mb:2*/}
                <CardActionArea >
                    <Box position='relative'>
                        <CardMedia
                            component="img"
                            height="180"
                            image="/images/card5.webp"
                            alt="Acumulación de basura"
                        />
                        <Container
                            sx={{
                                position: 'absolute',
                                left: -10,
                                right: 0,
                                bottom: -22,
                                //height: 10,
                            }}
                        >
                            <img src="/images/icon5.png" alt="Acumulación de Basura" className={classes.image} />
                        </Container>
                    </Box>
                    <Box position='relative' height={cardContentHeight}>
                        <CardContent>
                            <Typography gutterBottom variant="h6" fontWeight={
                                600
                            } component="div">
                                Acumulación de basura
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {/*  En caso exista en su localidad acumulación de basura o restos que no han sido recogidos con oportunidad, puede solicitar atención. */}
                                Enviamos unidades de recolección a su localidad.
                            </Typography>
                        </CardContent>
                    </Box>

                </CardActionArea>
            </Card>

        </Carousel>
    )
}

export default MultiCarousel2
