
import React, { useState } from 'react';
import { Box, Typography, Container, Button, TextField, Grid } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { fontSizeTheme, fontSizeThemeTitle } from './../../theme/ThemeFontSize';
import { Formik } from 'formik';
import { send } from '@emailjs/browser';
import Message from './../components/Message';



export default function Contactanos() {

    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [dataError, setDataError] = useState(false);
    const [notify, setNotify] = useState({ isOpen: false });

    return (
        <Box
        //minHeight="80vh"
        >
            {/*maxWidth='lg'*/}
            <section>
            <Container maxWidth='xl'
                sx={{
                    width: '100%',
                    //backgroundColor: '#D5D0CA',
                    py: {
                        lg: 10,
                        md: 10,
                        sm: 7,
                        xs: 5
                    },
                    px: 1,
                    position: 'relative',
                    //display: 'flex',
                    //flexDirection: 'row',
                }}
            >
                <Grid container >
                    <Grid item xs={12} sm={12} md={6}>
                        <Container maxWidth="sm" id='contactus'>
                            <Typography fontSize={fontSizeThemeTitle} variant="h4" color="#042D74" fontWeight={700} gutterBottom>
                                Contáctanos
                            </Typography>
                            <Typography fontSize={fontSizeTheme} color='text.secondary' fontWeight={500} variant="body1" >
                                Si tienes alguna duda rellena el siguiente formulario y nos pondremos en contacto.
                            </Typography>
                            <Typography variant="h5" fontSize={fontSizeThemeTitle} color="#042D74" fontWeight={700} gutterBottom
                                sx={{ pt: 4 }}
                            >
                                ¿No te gustan los formularios? Escríbenos al correo electrónico
                            </Typography>

                            <Box
                                sx={{
                                    //width: '100%',
                                    m: 0,
                                    p: 0,
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <EmailIcon />
                                <Typography sx={{ pl: 1 }} variant="body1" fontSize={fontSizeTheme} color='text.secondary' fontWeight={500}>
                                    innova.msg@gmail.com
                                </Typography>
                            </Box>
                            <Box
                                height={7}
                            ></Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <CallIcon />
                                <Typography sx={{ pl: 1 }} variant="body1" fontSize={fontSizeTheme} color='text.secondary' fontWeight={500}>
                                    +51 946066646
                                </Typography>
                            </Box>

                        </Container>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <Container maxWidth="sm" sx={{
                            pt: {
                                lg: 0,
                                md: 0,
                                sm: 3,
                                xs: 4
                            }
                        }}>
                            <Typography fontSize={fontSizeThemeTitle} variant="h5" fontWeight={700} color="#042D74" gutterBottom
                                sx={{ pt: 1, pb: 1 }}
                            >
                                Formulario de contacto
                            </Typography>


                            <Box
                                height={10}></Box>

                            {/* Formulario Formik */}
                            <Formik
                                initialValues={{ email: '', name: '', data: '' }}
                              
                                validate={values => {
                                    const errors = {};
                                    setNameError(false)
                                    setEmailError(false)
                                    setDataError(false)

                                    if (!values.email) {
                                        errors.email = '*Campo obligatorio';
                                        setEmailError(true)
                                    } else if (
                                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                    ) {
                                        errors.email = '*Dirección invalida';
                                        setEmailError(true)
                                    }

                                    if (!values.name) {
                                        errors.name = '*Campo obligatorio';
                                        setNameError(true)
                                    }

                                    if (!values.data) {
                                        errors.data = '*Campo obligatorio';
                                        setDataError(true)

                                    }

                                    return errors;
                                }}
                                onSubmit={(values, { setSubmitting, resetForm }) => {
                                    setTimeout(() => {
                                        //console.log(JSON.stringify(values, null, 2));
                                        const toSend = {
                                            email: values.email,
                                            name: values.name,
                                            data: values.data,
                                        }
                                        send(
                                            'service_bwb6psd',
                                            'template_q5700fv',
                                            toSend,
                                            'miWeC9dTZnQFh5zC_'
                                        )
                                            .then((response) => {
                                                console.log('SUCCESS!', response.status, response.text);
                                                setNotify({ isOpen: true, message: 'Hemos recepcionado su email, en breve nos pondremos en contacto!', type: 'success' })
                                            })
                                            .catch((err) => {
                                                console.log('FAILED...', err);
                                                setNotify({ isOpen: true, message: 'Ha ocurrido un error, por favor inténtelo más tarde.', type: 'error' })

                                            });
                                        //alert(JSON.stringify(values, null, 2));
                                        setSubmitting(false);
                                        resetForm();

                                    }, 400);
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,

                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                }) => (
                                    <form
                                        //noValidate
                                        //autoComplete='off'
                                        onSubmit={handleSubmit}>
                                        <TextField
                                            id="outlined-multiline-static"
                                            label="Realizanos una pregunta"
                                            multiline
                                            rows={4}
                                            fullWidth
                                            name="data"
                                            onChange={handleChange}
                                            value={values.data}
                                            error={dataError}
                                            helperText={errors.data} />
                                        <Box
                                            height={20}></Box>
                                        <TextField name="email" error={emailError} helperText={errors.email} onChange={handleChange} value={values.email} type="email" fullWidth label="Correo electrónico" />
                                        <Box
                                            height={20}></Box>
                                        <TextField name="name" error={nameError} helperText={errors.name} onChange={handleChange} value={values.name} fullWidth label="Nombres" id="fullWidth" />
                                        <Box
                                            height={20}></Box>
                                        <Button variant="contained" type='submit' disabled={isSubmitting} endIcon={<ArrowForwardIosIcon />}>
                                            <Typography variant="body1" color="inherit">Enviar</Typography>
                                        </Button>
                                    </form>
                                )}
                            </Formik>
                            {/* End section */}

                            <Message
                                notify={notify}
                                setNotify={setNotify}
                            />
                        </Container>
                    </Grid>
                </Grid>
            </Container>
            </section>
            
        </Box >
    )
}


/*

  <Box>
                    <Container maxWidth="md" id='place-to-visit'>
                        <Typography variant="h6" color='text.primary' gutterBottom>
                            CONTÁCTENOS
                        </Typography>
                        <Typography variant="body2" color='text.secondary'>
                            Si necesita de nuestros servicios, rellene el siguiente formulario y nos podremos en contacto usted.
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', mb: 2 }}>
                            <FaUserAlt />
                            <TextField sx={{ ml: 1, my: -0.3 }} id="input-with-sx" label="nombre" variant="standard" />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', mb: 2 }}>
                            <FaUserAlt />
                            <TextField sx={{ ml: 1, my: -0.3 }} id="input-with-sx" label="email" variant="standard" />
                        </Box>
                        <Button fullWidth type="submit" variant="contained" color='primary'>Enviar</Button>
                    </Container>
                </Box>
*/
