import { useLayoutEffect, useState } from 'react';

export default function useWindowPosition(id, trigger) {


    const [animation, setAnimation] = useState(false);


    useLayoutEffect(() => {

        function updatePosition() {
            const offsetHeight = window.document.getElementById(id).offsetHeight;
            //console.log("windowpageOffset",window.pageYOffset,offsetHeight*0.1 )
            if (window.pageYOffset > offsetHeight * trigger) { //0.05
                setAnimation(true);
            }
        }
        window.addEventListener('scroll', updatePosition);

        updatePosition();
        return () => window.removeEventListener('scroll', updatePosition);
    }, [id]);
    return animation;
}
