
import { createTheme } from '@mui/material';


const theme = createTheme({
  palette: {
    primary: {
      main: '#CB433B' //2nd theme: 232323 -- 29699A -3rd theme 465D67 --  4th theme: 4F2E26 -- Extra: 252525  
      // button D54E46  d43256
      //Propuestas icon/buttons  1)D54E46   2)8F2418  3)E5D681 4)C33424 5)AAB7CA 6)C33424  7)E9464B 8)EBAB3B
     },
    secondary: {
      main: '#658DA7', //2nd theme: C6525D -- FFB0B9 3rd theme D3C0A0 -- 4th theme: DE773C  -- Extra: 007A7A
      // nav 1f68bf   292838
      // Carrousel color    1)2F2E45   2)658DA7  3)67515E 4)E8A636 5)7DC9D6 6)2268BE  7)E9464B   8)BB4722
    },
    error: {
      main: '#8B3B2A'
    },
    success: {
      main: '#2e7d32'
    },
    info: {
      main: '#FCEEE3'  //  2nd theme: D3CAA1 -- 3rd theme: D8E7EA -- 4th theme: FFF5FD -- Extra: AED0D2   b9daeb faf5f2
      // Text box color and footer   1)B9DAEB   2)FCEEE3  3)D6DDED 4)BFEBFF 5)AAB7CA 6)F0ABA9  7)F4F2E5 8)EBBA9D
    },
    warning: {
      main: '#F9672C'
    },

    text: {
      primary: '#292838', //  2nd theme: 9C6928 -- 3rd theme: 465D67 -- 4th thme: 4F2E26 -- Extra: 252525
      // propuesta  292838   2F2E45
      //      1)292838   2)8F2418  3) 4) 5) 6)  7) 8)

      secondary: '#747785',  // Extra: C79E6D             
      //disabled
    },     
  }, typography: {
    fontFamily: 'Montserrat',
    fontWeightLight: 400,
    // fontWeightLight: 500,
    // fontWeightLight: 600,
    // fontWeightLight: 700,
  }
  //0,100;0,200;0,300;0,400;0,500;0,600;0,700

})

export default theme;

//https://stackoverflow.com/questions/40714583/how-to-specify-a-port-to-run-a-create-react-app-based-project/40714921#40714921