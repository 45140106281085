import { Box, Container, Typography, Button } from "@mui/material";
import Fade from "@mui/material/Fade";
import React, { useEffect } from "react";
import { Link as Scroll } from "react-scroll";
import useMediaQuery from "@mui/material/useMediaQuery";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Grid from "@mui/material/Grid";

export default function TitleSection() {
	const [checked, setChecked] = React.useState(false);
	// # Animation section
	useEffect(() => {
		setChecked(true);
	}, []);
	const matches = useMediaQuery("(min-width:600px)");

	return (
		<Box>
			<Box
				minHeight="70vh"
				id="inicio"
				sx={{
					width: "100%",
					//backgroundImage: `url(${"images/fondo2.png"})`,
					backgroundImage: `url(${"images/wallpaper/main.png"})`,
					position: "absolute",
					//opacity: [0.8, 0.9, 0.9],
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
				}}></Box>
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				minHeight="70vh"
				position="relative"
				sx={{
					p: { md: 2, xs: 0.5 },
				}}>
				<Container
					maxWidth="lg"
					sx={{
						pt: 2,
					}}>
					<Box display="flex" justifyContent="center" alignItems="center">
						<Container
							align="center"
							sx={{
								height: matches === true ? 200 : 170,
								width: matches === true ? 200 : 170,
								//backgroundColor: 'primary.main',
								backgroundImage: `url(${"images/logo/logo.png"})`,
								position: "relative",
								//opacity: [0.8, 0.9, 0.9],
								backgroundRepeat: "no-repeat",
								backgroundSize: "cover",
								mb: 2,
							}}></Container>
					</Box>
					<Fade
						in={checked}
						style={{ transformOrigin: "0 0 0" }}
						{...(checked ? { timeout: 1200 } : {})}>
						<Box>
							<Box
								sx={{
									borderRadius: 3,
									mx: { xs: 4 },
									p: 1,
									mb: 1.5,
									backgroundColor: "#E5E5E5", //E5E5E5 info.main
								}}>
								<Typography
									fontSize={{
										lg: 25,
										md: 25,
										sm: 25,
										xs: 22,
									}}
									variant="h1"
									fontWeight={700}
									color="text.primary"
									align="center"
									gutterBottom>
									{" "}
									{/*  sx={{ typography: { sm: 'h4', xs: 'h6' } }} */}
									Plataforma digital que te permite reportar incidentes y
									recibir una respuesta rápida
								</Typography>

								<Grid container justifyContent="center">
									<ImageList
										sx={{
											height: {
												lg: 50,
												md: 50,
												sm: 46,
												xs: 46,
											},
										}}
										cols={2}
										rowHeight={30}
										gap={4}>
										<ImageListItem>
											<a href="https://play.google.com/apps/testing/com.innova.guepardo">
												<img
													src="/images/web/appstore.png"
													alt="App Store Keeper"
													loading="lazy"
												/>
											</a>
										</ImageListItem>
										<ImageListItem>
											<a href="https://play.google.com/store/apps/details?id=com.innova.guepardo">
												<img
													src="/images/web/googleplay.png"
													//srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
													alt="Google Play Keeper"
													loading="lazy"
												/>
											</a>
										</ImageListItem>
									</ImageList>
								</Grid>
							</Box>
							<Typography
								sx={{ typography: { sm: "h6", xs: "body1" } }}
								variant="h2"
								color="#fafafa"
								fontWeight={700}
								align="center"
								gutterBottom>
								Construyamos juntos una comunidad segura
							</Typography>
							<Box
								display="flex"
								justifyContent="center"
								alignItems="center"
								sx={{
									p: 1,
								}}>
								<Scroll to="contactus" smooth={true}>
									<Button variant="contained" size="large" href={"/contact"}>
										<Typography
											sx={{ typography: { sm: "body1", xs: "body2" } }}
											fontWeight={600}
											color="colors.white">
											Contáctanos
										</Typography>
									</Button>
								</Scroll>
							</Box>
						</Box>
					</Fade>
				</Container>
			</Box>
		</Box>
	);
}
