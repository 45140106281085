import { Box, Container} from '@mui/material';
import React, { useEffect} from 'react';
import useWindowPosition from '../../hook/useWindowPosition';
import Grow from '@mui/material/Grow';
import MultiCarousel2 from '../components/MultiCarousel2';

export default function MultiCarrouselInfo2() {

    //Section for modal input
    const checkedAnimationCarrousel = useWindowPosition('header', 0.22);
    const [checked, setChecked] = React.useState(false);


    useEffect(() => {
        setChecked(true);
    }, [])

    // const matches = useMediaQuery('(min-width:600px)');

    return (
        <Box>
            <Grow
                in={checkedAnimationCarrousel}
                {...(checked ? { timeout: 1500 } : {})}
            >

                <Container maxWidth="xl"
                    sx={{
                        position: 'relative',
                        left: 0,
                        right: 0,
                        bottom: 10,
                        m: 0.5,
                        py: 2,
                        px: 2
                    }}
                >

                    <MultiCarousel2 />
                </Container>

            </Grow >

        </Box>
    )
}
