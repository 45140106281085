import {
	Box,
	Container,
	Typography,
	List,
	ListItem,
	ListItemText,
} from "@mui/material";
import React from "react";
import { fontSizeTheme, fontSizeThemeTitle } from "./../../theme/ThemeFontSize";

export default function DeleteAccount() {
	return (
		<Box
			minHeight="70vh"
			sx={{
				position: "relative",
				display: "flex",
				flexDirection: "row",
				px: 1,
				py: 4,
			}}>
			<Container maxWidth="md">
				<Typography
					fontSize={fontSizeThemeTitle}
					sx={{ pb: 1 }}
					variant="h6"
					color="#042D74"
					fontWeight={600}
					align="center"
					gutterBottom>
					¿Cómo puedo eliminar mi cuenta?
				</Typography>
				<Typography
					display="block"
					fontSize={fontSizeTheme}
					variant="body1"
					color="text.secondary"
					fontWeight={500}
					align="justify"
					gutterBottom
					style={{ lineHeight: "30px" }}>
					Lamentamos que estés considerando eliminar tu cuenta. Entendemos que
					cada usuario tiene sus propias razones y queremos asegurarnos de que
					el proceso sea lo más sencillo posible para ti. A continuación,
					encontrarás un paso a paso para eliminar tu cuenta:
				</Typography>
				<List>
					<ListItem>
						<ListItemText
							primary="Inicia sesión en tu cuenta."
							secondary="Asegúrate de estar conectado a la cuenta que deseas eliminar."
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary="Dirígete a tu perfil."
							secondary="En la esquina superior izquierda (en el menú desplegable), busca la opción 'Mi cuenta'."
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary="Abre las configuraciones de la cuenta."
							secondary="Dentro de la configuración, busca la opción 'Eliminar cuenta'."
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary="Introduce tu contraseña."
							secondary="Por seguridad, te pediremos que verifiques introduciendo tu contraseña. Luego, confirma para pasar al siguiente paso."
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary="Revisa tu bandeja de entrada."
							secondary="Después de confirmar la eliminación de tu cuenta, verifica tu bandeja de entrada del correo electrónico asociado a tu cuenta."
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary="Ingresa al enlace proporcionado."
							secondary="Serás redirigido a una página para completar el proceso de eliminación de la cuenta."
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary="Confirma la eliminación."
							secondary="Revisa todos los detalles y, cuando estés seguro de tu decisión, confirma la eliminación de la cuenta. Se te solicitará ingresar tu contraseña nuevamente para confirmar."
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary="Recibe confirmación."
							secondary="Después de completar el proceso, deberías recibir una confirmación de que tu cuenta ha sido eliminada."
						/>
					</ListItem>
				</List>
			</Container>
		</Box>
	);
}
