import React from 'react';
import { Box, Typography, Container,  Grid,  Link } from '@mui/material';
import { GrFacebook,  GrInstagram } from "react-icons/gr";


//set PORT=3100 && 

function FooterComponent() {
    return (
        <Box
            component="footer"
            sx={{
                py: 2,
                px: 2,
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? 'info.main'  //F0F0F0
                        : theme.palette.grey[800],
            }}
        >
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                    <Services />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SystemsDeveloped />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SocialMedia />
                </Grid>
                {/*
                <Grid item xs={12} sm={6} md={3}>
                    <Contancts />
                </Grid>
                */}
            </Grid>
        </Box>

    )
}

export default FooterComponent;




function Services() {
    return (
        <Box>
            <Container maxWidth="sm">
                <Typography variant="h6" color='text.primary' gutterBottom>
                    KEEPER
                </Typography>
                <Typography variant="body2" color='text.secondary'>
                    Es un sistema de gestión inteligente que se apoya en el uso de la tecnología para resolver emergencias y alertas en el menor tiempo posible.
                </Typography>
            </Container>

        </Box>
    );
}

function SystemsDeveloped() {
    return (
        <Box>
            <Container maxWidth="sm" sx={{ pl: 2 }}>
                <Typography variant="h6" color='text.primary' gutterBottom>
                    COMPONENTES
                </Typography>
                <Typography variant="body2" color='text.secondary'>
                    <li>App Android</li>
                </Typography>
                <Typography variant="body2" color='text.secondary'>
                    <li> App IOS</li>
                </Typography>
                <Typography variant="body2" color='text.secondary'>
                    <li>  Pagina web de gestión y mantenimiento</li>
                </Typography>
                <Typography variant="body2" color='text.secondary'>
                    <li>Bases de datos</li>
                </Typography>
            </Container>
        </Box>
    );
}

function SocialMedia() {
    return (<Box

    >
        <Container maxWidth="sm">
            <Typography variant="h6" color='text.primary' gutterBottom>
                REDES SOCIALES
            </Typography>
            <Typography variant="subtitle2" color='text.secondary'>
                <GrFacebook></GrFacebook>  <Link sx={{ pl: 0.5 }} color="inherit" href='https://www.facebook.com/soskeeper'>
                    soskeeper
                </Link>
            </Typography>



            <Typography variant="subtitle2" color='text.secondary'>
                <GrInstagram></GrInstagram>   <Link sx={{ pl: 0.5 }} color="inherit" href='https://www.instagram.com/soskeeper/'>
                    @sosKeeper
                </Link>
            </Typography>
        </Container>

    </Box>
    );
}

// function Contancts() {
//     return (
//         <Box>
//             <Container maxWidth="sm" id='place-to-visit'>
//                 <Typography variant="h6" color='text.primary' gutterBottom>
//                     CONTÁCTENOS
//                 </Typography>
//                 <Typography variant="body2" color='text.secondary'>
//                     Si necesita de nuestros servicios, rellene el siguiente formulario y nos podremos en contacto usted.
//                 </Typography>
//                 {/*
//                 <TextField

//                     sx={{
//                         p: 1
//                     }}
//                     id="input-with-icon-textfield"
//                     //label="TextField"
//                     InputProps={{
//                         startAdornment: (
//                             <InputAdornment position="start">
//                                 <FaUserAlt />
//                             </InputAdornment>
//                         ),
//                     }}
//                     variant="standard"
//                 />
//                 */}
//                 <Box sx={{ display: 'flex', alignItems: 'flex-end', mb: 2 }}>
//                     <FaUserAlt />
//                     <TextField sx={{ ml: 1, my: -0.3 }} id="input-with-sx" label="nombre" variant="standard" />
//                 </Box>
//                 <Box sx={{ display: 'flex', alignItems: 'flex-end', mb: 2 }}>
//                     <FaUserAlt />
//                     <TextField sx={{ ml: 1, my: -0.3 }} id="input-with-sx" label="email" variant="standard" />
//                 </Box>
//                 <Button fullWidth type="submit" variant="contained" color='primary'>Enviar</Button>
//             </Container>
//         </Box>
//     );
// }
