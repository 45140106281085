

const fontSizeTheme = {
    lg: 16,
    md: 16,
    sm: 15,
    xs: 14
}
const fontSizeThemeTitle = {
    lg: 23,
    md: 23,
    sm: 18,
    xs: 17
}

export { fontSizeTheme, fontSizeThemeTitle }