import { Box, Container, Typography } from "@mui/material";
import React from "react";
import "../../App";
import TitleSection from "./titleSection";
import Information from "./Information";
import CarrouselInfo from "./CarrouselInfo";
import MultiCarrouselInfo2 from "./MultiCarrouselInfo2";
import MobileSection from "./MobileSection";
import WebSection from "./WebSection";
import Contactanos from "./Contactanos";

function HomePage() {
	return (
		<div
			id="header"
			sx={{
				p: 0,
				display: "flex",
				flexDirection: "column",
				backgroundColor: "#FFFBFD",
			}}>
			{/* Parte 1: Main tittle section. */}
			<TitleSection />
			<Box>
				{/* Parte 2: Main tittle section. */}
				<Information />
				{/* <KeyFunctions /> */}

				{/* Parte 3: Carrousel Section. */}
				<CarrouselInfo />

				{/* Parte 4: Single Image Section. */}
				<Container maxWidth="md">
					<Typography
						fontSize={{
							lg: 25,
							md: 25,
							sm: 20,
							xs: 17,
						}}
						color="#042D74"
						fontWeight={600}
						align="center"
						gutterBottom>
						{" "}
						{/* fontWeight={700} */}
						Te brindamos ayuda en las siguientes situaciones
					</Typography>
				</Container>
				{/* <MultiCarrouselInfo /> */}
				<MultiCarrouselInfo2 />

				{/* Mobile section.  */}
				<MobileSection />

				{/* Web section. */}
				<WebSection />

				{/* Contactanos */}

				<Contactanos />
			</Box>
		</div>
	);
}

export default HomePage;

/*
                    sx={{
                        m: 1,
                        //width: '100%',
                        //backgroundImage: `url(${"images/mapa.jpg"})`,
                        // opacity: [0.8, 0.9, 0.7],
                        //boxShadow: '0 1px 5px 2px rgba(255, 105, 135, .3)',
                        //position: 'absolute',
                        //backgroundRepeat: 'no-repeat',
                        //backgroundSize: 'cover'
                    }}
    
                */
