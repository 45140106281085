import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './App.css';
import CssBaseline from '@mui/material/CssBaseline';
import {  ThemeProvider } from '@mui/material';
import theme from './theme/innovaTheme';
import HomePage from './ui/pages/homePage';
import Layout from './ui/components/layout';
import Contactanos from './ui/pages/Contactanos';
import PrivacyPolitics from './ui/pages/PrivacyPolitics';
import NotFound from './ui/pages/404';
import React  from 'react';
import DeleteAccount from './ui/pages/DeleteAccount';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Layout>
          <CssBaseline/>
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/contacto" element={<Contactanos/>} />
            <Route exact path="/privacidad" element={<PrivacyPolitics/>} />
            <Route exact path='*' element={<NotFound/>} />
            <Route exact path='/deleteaccount' element= {<DeleteAccount/>}/>
          </Routes>
        </Layout>
      </Router>
    </ThemeProvider>
  );
}

export default App;


/*
   <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <CssBaseline />
        <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
          <Typography variant="h2" component="h1" gutterBottom>
            Sticky footer
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom>
            {'Pin a footer to the bottom of the viewport.'}
            {'The footer will move as the main element of the page grows.'}
          </Typography>
          <Typography variant="body1">Sticky footer placeholder.</Typography>
        </Container>
        <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            mt: 'auto',
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? '#F0F0F0'
                : theme.palette.grey[800],
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={3}>
              <SystemsDeveloped />
            </Grid>
            <Grid item xs={3}>
              <Services />
            </Grid>
            <Grid item xs={3}>
              <SocialMedia />
            </Grid>
            <Grid item xs={3}>
              <Contancts />
            </Grid>
          </Grid>

        </Box>
      </Box>
*/