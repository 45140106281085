
import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import Carousel from 'react-material-ui-carousel'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

export default function CarrouselInfo() {

    var items = [

        {
            name: "Centro de llamadas",
            description: "Coordinación centralizada",
            image: "/images/carrousel/carrousel1.jpg"
        },
        {
            name: "Monitoreo en tiempo real",
            description: "Desde cualquier lugar, hora y dispositivo",
            image: "/images/carrousel/carrousel2.png"
        },
        {
            name: "Vecinal",
            description: "Aplicación disponible en Android y IOS",
            image: "/images/carrousel/carrousel3.png"
        },
        {
            name: "Data estratégica",
            description: "Ayuda en la toma de decisiones",
            image: "/images/carrousel/carrousel4.jpg"
        }
    ]

    return (
        <Box
            sx={{
                p: {
                    lg: 5,
                    md: 5,
                    sm: 5,
                    xs: 2
                },
                position: 'relative',

            }}
        >
            <Carousel animation='slide' duration={600} cycleNavigation navButtonsAlwaysVisible>
                {items.map((item, i) => <Item key={i} item={item} />)}
            </Carousel>

        </Box>
    )
}



function Item(props) {
    return (
        <Card sx={{
            display: 'flex', height: {
                lg: 450,
                md: 450,
                sm: 400,
                xs: 300
            }, backgroundColor: '#292838'
        }}>  {/* secondary.main */}
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Container>
                    <CardContent
                        sx={{ px: 0 }}
                    > {/* flex: '1 0 auto' */}
                        <Typography sx={{ py: 1 }} variant="h5" fontSize={{
                            lg: 30,
                            md: 35,
                            sm: 23,
                            xs: 15
                        }} align='center' color='info.main' gutterBottom fontWeight={600}>
                            {props.item.name}
                        </Typography>

                        <Container >
                            <Typography variant="subtitle1" fontSize={{
                                lg: 20,
                                md: 20,
                                sm: 16,
                                xs: 12
                            }} align='center' color="#FFFBFD" fontWeight={500}>
                                {props.item.description}
                            </Typography>
                        </Container>
                    </CardContent>
                </Container>
            </Box>
            <CardMedia
                component="img"
                sx={{ width: '60%', display: 'flex', flex: '1 0 auto' }}
                image={props.item.image}
                alt={props.item.name}
            />
        </Card>
    )
}
