import { Box, Container, Typography } from '@mui/material'
import React, { useEffect} from 'react';
import Collapse from '@mui/material/Collapse';
import useWindowPosition from '../../hook/useWindowPosition';


export default function Information() {
    const [checked, setChecked] = React.useState(false);
    const checkedAnimation = useWindowPosition('header', 0.05);

    useEffect(() => {
        setChecked(true);
    }, [])
    // const matches = useMediaQuery('(min-width:600px)');

    return (
        <Collapse
            in={checkedAnimation}
            {...(checked ? { timeout: 1000 } : {})}
        >
            <Box
                minHeight="25vh"
                sx={{
                    p: { lg: 2, xs: 0.5 },
                    pt:
                        { lg: 3, xs: 0.5 },
                    position: 'relative',
                }}>
                <Container >
                    <Container maxWidth="lg" sx={{ p: 2 }} >
                        <Typography fontSize={{
                            lg: 25,
                            md: 25,
                            sm: 20,
                            xs: 17
                        }} variant="h1" color="#042D74" fontWeight={600} align='center' gutterBottom>  {/* fontWeight={700}   042D74 */}
                            Contribuimos a que la ciudadanía se sienta segura
                        </Typography>
                    </Container>
                        <Typography sx={{ typography: { lg: 'subtitle1', xs: 'body2' } }} color='text.secondary' align='center' gutterBottom>
                            Keeper es un sistema de gestión inteligente
                        </Typography>
                    

                    <Typography sx={{ typography: { lg: 'subtitle1', xs: 'body2' } }} color='text.secondary' align='center' gutterBottom>
                        Keeper facilita la comunicación eficiente entre instituciones públicas y vecinos
                    </Typography>
                    <Typography sx={{ typography: { lg: 'subtitle1', xs: 'body2' } }} color='text.secondary' align='center' gutterBottom>
                        Fortalece la confianza pública
                    </Typography>
                    <Typography sx={{ typography: { lg: 'subtitle1', xs: 'body2' } }} color='text.secondary' align='center' gutterBottom>
                        Mejora el tiempo de respuesta
                    </Typography>
                    <Typography sx={{ typography: { lg: 'subtitle1', xs: 'body2' } }} color='text.secondary' align='center' gutterBottom>
                        Crea transparencia
                    </Typography>
                </Container>
            </Box>
        </Collapse>
    )
}
